import { useLocalStorage, StorageSerializers } from "@vueuse/core";
import { mapaChavePerfil } from "@/types/enums";

const useAnyListFilter = () => {
  const nameFilter = ref("genericFilterList");
  const internalFilter = reactive({
    [nameFilter.value]: {},
  } as any);

  const setNameFilter = (value) => {
    nameFilter.value = value;

    clearAll(nameFilter.value);

    if (!internalFilter.hasOwnProperty(nameFilter.value)) {
      internalFilter[nameFilter.value] = useLocalStorage(
        nameFilter.value,
        {} as any,
        {
          serializer: StorageSerializers.object,
        }
      );
    }
  };

  const set = (anyListFilter: any) => {
    internalFilter[nameFilter.value] = { ...anyListFilter };
  };

  const clear = () => {
    internalFilter[nameFilter.value] = {};
  };

  const filter = computed(() => internalFilter[nameFilter.value]);

  const clearAll = (exceptKey?: string) => {
    mapaChavePerfil
      .filter((chave) => (exceptKey != null ? chave !== exceptKey : true))
      .map((chave) => {
        if (!internalFilter.hasOwnProperty(chave)) {
          internalFilter[chave] = useLocalStorage(chave, {} as any, {
            serializer: StorageSerializers.object,
          });
        }

        internalFilter[chave] = {};
      });
  };

  return reactive({
    nameFilter,
    filter,
    fetch,
    clear,
    clearAll,
    set,
    setNameFilter,
  });
};

export default useAnyListFilter();
